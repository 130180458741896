import React, {useEffect, useState} from "react";
import {Drawer, Form, Input} from "antd";
import Cookies from "js-cookie";
import getID from "@/utils/getID";
import {useRouter} from "next/router";
import Link from "next/link";
import {generateCSRFToken} from "@/utils/csrf";

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchMobileSidebar: React.FC<Props> = ({setShow, show}) => {
  const [form] = Form.useForm();
  const router = useRouter();
  const [history, setHistory] = useState<{value: string; label: string}[]>([]);
  const csrfToken = generateCSRFToken();

  const getHistoryCookies = () => {
    let search_history: {value: string; label: string}[] = [];
    const current_user_hsitory: string | undefined = Cookies.get("s_history");

    if (current_user_hsitory) {
      search_history = JSON.parse(current_user_hsitory);
    }

    setHistory(search_history);
  };

  const searchHandler = (data: any) => {
    // get cookies
    getHistoryCookies();
    const formData = new FormData();
    formData.append("_csrf", csrfToken); // Use the appropriate field name

    // set to cookies
    let payload = history;

    if (payload.length < 5) {
      payload.unshift({label: data.search || "", value: data.search || ""});
    } else {
      payload = payload.slice(0, 4);
      payload.unshift({label: data.search || "", value: data.search || ""});
    }

    // save cookie
    Cookies.set("s_history", JSON.stringify(payload));

    // refetch
    getHistoryCookies();
    form.resetFields();

    // go to product
    router.push({
      pathname: "/product",
      query: {
        search: data.search,
      },
    });
  };

  const clearHistory = () => {
    Cookies.remove("s_history");
    setHistory([]);
  };

  useEffect(() => {
    getHistoryCookies();
  }, []);

  return (
    <Drawer
      className="search-mobile-sidebar"
      placement="left"
      closable={false}
      bodyStyle={{padding: 0}}
      width="700"
      mask
      // rootClassName="drawer-hide-shadow cart-sidebar w-full"
      maskClassName="bg-transparent md:bg-black/30"
      onClose={() => setShow((prev) => !prev)}
      open={show}
    >
      <section className="sidebar-body-wrapper">
        <Form
          autoComplete="off"
          form={form}
          onFinish={searchHandler}
          className="mt-5 "
          name="wrap"
          layout="vertical"
          colon={false}
          requiredMark={false}
        >
          <input type="hidden" name="_csrf" value={csrfToken} />
          <Form.Item label="" name="search" rules={[{required: true, message: "Field Required"}]}>
            <Input
              className="text-white bg-transparent border-white outline-none focus:bg-transparent placeholder:text-white"
              size="large"
              placeholder="What are you looking for?"
            />
          </Form.Item>
        </Form>

        <section className="py-0 ">
          {history.length ? (
            <>
              <div className="flex justify-between">
                <h5 className="font-medium text-white uppercase font-Helvetica">search history</h5>
                <button onClick={clearHistory} className="p-0 italic bg-transparent h-fit">
                  Clear All
                </button>
              </div>
              <ul className="mt-[14px] flex flex-col gap-[10px]">
                {history.map((h) => (
                  <li key={getID()}>
                    <Link className="text-white" href={"/product?search=" + h.value}>
                      {h.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <div className="text-center ">
              <h4 className="text-white">You dont have any history yet</h4>
            </div>
          )}
        </section>
      </section>
    </Drawer>
  );
};

export default SearchMobileSidebar;
