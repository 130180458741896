import {create} from "zustand";

interface IUiStoreState {
  openRestrictionCountry: boolean;
}

interface uiStoreType extends IUiStoreState {
  setRestrictionCountry: (state: boolean) => void;
}

const uiStore = create<uiStoreType>()((set) => ({
  openRestrictionCountry: false,

  setRestrictionCountry: (open) => {
    set((state) => ({...state, openRestrictionCountry: open}));
  },
}));

export default uiStore;

