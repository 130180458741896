// Hooks
import React from "react";
import userCartStore from "@/store/cartStore";

// Components
import {Button, Drawer} from "antd";
import CartProductItem from "./CartProductItem";

// Utils & Libs
import PriceFormatter from "@/utils/PriceFormatter";

const CartSideBar: React.FC<{open: boolean; setOpen: React.Dispatch<boolean>}> = ({open, setOpen}) => {
  const {carts, subtotal} = userCartStore();

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      title={null}
      closeIcon={null}
      closable={false}
      placement="right"
      className="large-sidebar"
      bodyStyle={{padding: 0}}
      width={"100%"}
    >
      {/* menu */}
      <section className="px-6 ">
        <section className="flex justify-end my-6">
          <Button onClick={() => setOpen(false)} className="p-0 border-none h-fit">
            <img src="/icons/close-mobile-sidebar.svg" alt="" />
          </Button>
        </section>

        {carts.length ? (
          <React.Fragment>
            <section className="flex items-center justify-between uppercase">
              <h2 className="font-semibold text-blue-base">CART</h2>
              <h2 className="mb-0 font-semibold text-blue-base">{carts.length} items</h2>
            </section>

            <section className="mt-8 space-y-4 h-[calc(60vh_-_14px)] overflow-y-auto">
              {carts.map((cart) => {
                return <CartProductItem key={cart.id} data={cart} />;
              })}
            </section>

            <section>
              <section className="flex justify-between font-semibold">
                <h2>SUBTOTAL</h2>
                <h2>{PriceFormatter(+subtotal || 0)}</h2>
              </section>
              <section className="flex flex-col space-y-4">
                <Button href="/cart" size="large">
                  VIEW MY CART
                </Button>
                <Button href="/checkout" size="large" type="primary">
                  CHECKOUT
                </Button>
              </section>
            </section>
          </React.Fragment>
        ) : (
          <section className="flex items-center justify-center mt-[45%]">
            <div className="max-w-[280px] text-center">
              <img className="-ml-4" src="/icons/cart-blue-icon.svg" alt="" />

              <h2 className="mt-4 text-lg font-semibold text-blue-base">Your cart is empty</h2>
              <p>Ladies, your shopping cart is still empty. Shop now and get all your favorite products.</p>
              <Button size="large" type="primary">
                SHOP NOW
              </Button>
            </div>
          </section>
        )}
      </section>
    </Drawer>
  );
};

export default CartSideBar;

