import csrf from "csrf";

const tokens = new csrf();
const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET;

export function generateCSRFToken() {
  const token = tokens.create(SECRET_KEY || "");
  return token;
}

export function verifyCSRFToken(token: string) {
  return tokens.verify(SECRET_KEY || "", token);
}

