import {Button, Modal} from "antd";
import React from "react";

const RestrictCountryModal: React.FC<{open: boolean; setOpen: React.Dispatch<boolean>}> = ({open, setOpen}) => {
  return (
    <Modal
      closable={false}
      footer={null}
      centered
      open={open}
      className="w-11/12 max-w-[450px]"
      onCancel={() => setOpen(false)}
    >
      <section className="pt-4 pb-2">
        <div className="flex justify-center">
          <img src="/icons/alert-icon.png" alt="success-icon" />
        </div>

        <div className="mt-4 text-center">
          <p className="mx-auto mt-4 text-blue-dark">Your country / destination is not covered.</p>
        </div>

        <Button
          onClick={() => {
            setOpen(false);
          }}
          className="mt-4"
          type="primary"
          size="large"
          block
        >
          CLOSE
        </Button>
      </section>
    </Modal>
  );
};

export default RestrictCountryModal;

